import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Представительство в судах | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Представительство в суде юристом с большой практикой позволяет сделать то, что сложно выполнить самостоятельно" />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/predstavitelstvo-v-sudah'
				name="Представительство в судах | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Представительство в суде юристом с большой практикой позволяет сделать то, что сложно выполнить самостоятельно"
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Представительство в судах" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Представительство в судах"}
			
				html={

				`
				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Когда нужно отстаивать собственные интересы в любом суде, многие люди и руководители организаций теряются. Это место, где нужно четко, аргументировано и максимально кратко выражать свое мнение, доказывать и уметь внимательно слушать оппонента. В судебной инстанции важно иметь представителя, который разбирается в проблеме и может предоставить квалифицированную юридическую помощь. Именно для этого нужно представительство в суде Казани.
				</p>

				<p class="TextText">
					<b>Значение правового помощника</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Услуги представительства в суде появились почти одновременно с созданием самих судов. На представителя возлагают большое количество функций, которые он выполняет в соответствии с заключенным договором.
				</p>

				<p class="TextText">
					<b>Когда нужно обратиться за представительством:</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Нет возможности посещать судебный процесс или нет желания;
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Сложный предмет спора, объемная доказательная база или наоборот — полное ее отсутствие.
				</p>

				<p class="TextText">
				Представительство в суде юристом с большой практикой позволяет сделать то, что сложно выполнить самостоятельно — составить и подать иск, отказаться от предъявляемых требований, подготовить жалобу, претензию или ходатайство. Юрист поможет направить дело для рассмотрения другим судьей или обжалования в суде другой инстанции.
				</p>

				<p class="TextText">
				При этом у такого представительства в суде стоимость будет зависеть от объема работы, которую предстоит выполнить. Чем сложнее дело, тем больше сил и времени придется в него вкладывать.
				</p>

				<p class="TextText">
					<b>Что будем делать
					</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					После заключения соглашения на представительство в суде цена услуг определена, и юрист начинает работу. Он разрабатывает стратегию дальнейших действий — залог успеха будущего дела. Изучает материалы дела, учитывает все обстоятельства, готовит иск или отзыв/возражения.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Представитель знает, как реагировать на самые разные ситуации, понимает, в какой момент стоит ходатайствовать или показывать дополнительные доказательства. На него не действует моральное давление со стороны оппонента или суда, эта обстановка для него привычна, а вторая сторона — чужой и не знакомый человек. Это одна из главных причин, почему стоит обратиться к специалисту, ведь нервы и время важно экономить.
				</p>

				<p class="TextText">
					<b>Комплекс правовых услуг</b>
				</p>

				<p class="TextText">
					детальное изучение дела, последовательный разбор с клиентом каждого момента
				</p>

				<p class="TextText">
					консультации, которые могут быть представлены в письменном и в устном виде по любому вопросу, возникающему у клиента в ходе процесса
				</p>

				<p class="TextText">
					непосредственное участие адвоката в суде, подготовка судебных документов: иска, ходатайств, жалоб
				</p>

				<p class="TextText">
					контроль за исполнением решения, если оно было вынесено в пользу клиента
				</p>

				<p class="TextText">
					подача жалобы в вышестоящую инстанцию, если принятый судом вердикт противоречит интересам клиента и действующему законодательству
				</p>

				<p class="TextText">
					<b>Как не ошибиться при выборе специалиста</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Во многом именно от работы представителя будет зависеть исход дела. Практика знает случаи, когда, казалось бы, самое бесперспективное из них заканчивалось неожиданным образом или напротив сторона, которая была твердо уверенна в своей правоте и доказательствах, отказавшись от юриста или не ответственно подойдя к выбору своего правового помощника, в итоге сожалела об этом.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Компания Алмаз функционирует на ранке оказания правовых услуг не первый год и зарекомендовала себя, как надежного помощника, позволяющего решать даже самые сложные проблемы клиентов. Квалифицированные специалисты готовы продемонстрировать высокий уровень их теоретических знаний и практических навыков работы.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
